
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import Avatar from '../components/Avatar';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/userSlice';

const CheckPasswordPage = () => {
  const [data, setData] = useState({
    password: "",
    username: "",
    role: ""
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // console.log(location.state)

  useEffect(() => {
    if (!location?.state?.name) {
      navigate('/email');
    } else {
      setData(prevData => ({
        ...prevData,
        username: location.state.username || "",
        role: location.state.role || ""
      }));
    }
  }, [location, navigate]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
  
    console.log('Form data:', data);
  
    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/password`;
  
    try {
      const response = await axios.post(URL, {
        username: data.username,
        password: data.password,
        role: data.role
      }, {
        withCredentials: true
      });
  
      console.log('Response:', response.data);
  
      toast.success(response.data.message);
  
      if (response.data.success) {
        dispatch(setToken(response?.data?.token));
        localStorage.setItem('token', response?.data?.token);
  
        setData({
          password: "",
          username: "",
          role: ""
        });
        navigate('/');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(error?.response?.data?.message || 'An error occurred');
    }
  };
  

  return (
    <div className='mt-14'>
      <div className='bg-white w-full max-w-md rounded overflow-hidden p-4 mx-auto'>
        <div className='w-fit mx-auto mb-2 flex justify-center items-center flex-col'>
          <Avatar
            width={70}
            height={70}
            name={location?.state?.name}
            imageUrl={location?.state?.profile_pic}
          />
          <h2 className='font-semibold text-lg mt-1'>{location?.state?.name}</h2>
        </div>

        <form className='grid gap-4 mt-3' onSubmit={handleSubmit}>
  {/** Hidden Username Field */}
  <div className='hidden'>
    <label htmlFor='username'>Username</label>
    <input
      type='text'
      id='username'
      name='username'
      value={data.username}  // Assuming you have a username field in your data state
      onChange={handleOnChange}
      autoComplete='username'
    />
  </div>

  {/** Password Field */}
  <div className='flex flex-col gap-1'>
    <label htmlFor='password'>Password:</label>
    <input
      type='password'
      id='password'
      name='password'
      placeholder='Enter your password'
      className='bg-slate-100 px-2 py-1 focus:outline-primary'
      value={data.password}
      onChange={handleOnChange}
      required
      autoComplete='current-password'
    />
  </div>

  {/** Submit Button */}
  <button
    type='submit'
    className='bg-primary text-lg px-4 py-1 hover:bg-secondary rounded mt-2 font-bold text-white leading-relaxed tracking-wide'
  >
    Login
  </button>
</form>


        <p className='my-3 text-center'>
          <Link to="/forgot-password" className='hover:text-primary font-semibold'>
            Forgot password?
          </Link>
        </p>
      </div>
    </div>
  );
};

export default CheckPasswordPage;
