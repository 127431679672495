import React, { useEffect, useState } from 'react';
import { IoChatbubbleEllipses } from 'react-icons/io5';
import { FaUserPlus, FaImage, FaVideo } from 'react-icons/fa';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import Avatar from './Avatar';
import { useDispatch, useSelector } from 'react-redux';
import EditUserDetails from './EditUserDetails';
import SearchUser from './SearchUser';
import { logout } from '../redux/userSlice';
import { setRooms } from '../redux/roomSlice';
import '../index.css';

const Sidebar = () => {
    const user = useSelector((state) => state?.user);
    const [editUserOpen, setEditUserOpen] = useState(false);
    const [allUser, setAllUser] = useState([]);
    const [openSearchUser, setOpenSearchUser] = useState(false);
    const socketConnection = useSelector((state) => state?.user?.socketConnection);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const isRoomsPath = currentPath.startsWith('/rooms');

    useEffect(() => {
        if (socketConnection) {
            socketConnection.emit('sidebar', user.id);

            const handleConversation = (data) => {
                const conversationUserData = data.map((conversationUser) => {
                    if (conversationUser?.sender?.id === conversationUser?.receiver?.id) {
                        return {
                            ...conversationUser,
                            userDetails: conversationUser?.sender,
                        };
                    } else if (conversationUser?.receiver?.id !== user?.id) {
                        return {
                            ...conversationUser,
                            userDetails: conversationUser.receiver,
                        };
                    } else {
                        return {
                            ...conversationUser,
                            userDetails: conversationUser.sender,
                        };
                    }
                });

                setAllUser(conversationUserData);
            };

            const handleRooms = (rooms) => {
                dispatch(setRooms(rooms));
            };

            socketConnection.on('conversation', handleConversation);
            socketConnection.on('rooms', handleRooms);

            return () => {
                socketConnection.off('conversation', handleConversation);
                socketConnection.off('rooms', handleRooms);
            };
        }
    }, [socketConnection, user.id, dispatch]);

    const handleLogout = () => {
        dispatch(logout());
        navigate('/email');
        localStorage.clear();
    };

    return (
        <div className={`w-full h-full grid grid-cols-[48px,1fr] ${isRoomsPath ? 'bg-gray-100' : 'bg-white'}`}>
            <div className="bg-slate-100 w-12 h-full rounded-tr-lg rounded-br-lg py-5 text-slate-600 flex flex-col justify-between">
                <div>
                    <NavLink 
                        to="/" 
                        className={({ isActive }) => `w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded ${isActive && 'bg-slate-200'}`} 
                        title="chat"
                    >
                        <IoChatbubbleEllipses size={20} />
                    </NavLink>

                    <div 
                        title="search user" 
                        onClick={() => setOpenSearchUser(true)} 
                        className="w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded"
                    >
                        <FaUserPlus size={20} />
                    </div>
                </div>

                <div className="flex flex-col items-center">
                    <button 
                        className="mx-auto" 
                        title={user?.name} 
                        onClick={() => setEditUserOpen(true)}
                    >
                        <Avatar width={40} height={40} name={user?.name} imageUrl={user?.profile_pic} userId={user?.id} />
                    </button>

                    <button 
                        title="logout" 
                        className="w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded" 
                        onClick={handleLogout}
                    >
                        <span className="-ml-2">
                            <BiLogOut size={20} />
                        </span>
                    </button>
                </div>
            </div>

            <div className="w-full">
                <div className="h-16 flex items-center">
                    <h2 className="text-xl font-bold p-4 text-slate-800">{isRoomsPath ? 'Rooms' : 'Chat Message'}</h2>
                </div>
                <div className="bg-slate-200 p-[0.5px]"></div>

                <div className={`h-[calc(100vh-65px)] overflow-x-hidden overflow-y-auto scrollbar ${isRoomsPath ? 'bg-gray-200' : ''}`}>
                    {isRoomsPath ? (
                        <div className="p-4">
                            <h1 className="text-2xl font-bold mb-4"> </h1>
                            {/* Render room list here or other room-related content */}
                        </div>
                    ) : allUser.length === 0 ? (
                        <div className="mt-12">
                            <div className="flex justify-center items-center my-4 text-slate-500"></div>
                            <p className="text-lg text-center text-slate-400">No users found</p>
                        </div>
                    ) : (
                        allUser.map((conv) => (
                            <NavLink 
                                to={'/' + conv?.userDetails?.id} 
                                key={conv?.id} 
                                className="flex items-center gap-2 py-3 px-2 border border-transparent hover:border-primary hover:bg-slate-100 cursor-pointer"
                            >
                                <div>
                                    <Avatar imageUrl={conv?.userDetails?.profile_pic} name={conv?.userDetails?.name} width={40} height={40} />
                                </div>
                                <div className="flex-grow">
                                    <h3 className="text-ellipsis line-clamp-1 font-semibold text-base">{conv?.userDetails?.name}</h3>
                                    <div className="text-slate-500 text-xs flex items-center gap-1">
                                        {conv?.lastMsg?.imageUrl && (
                                            <div className="flex items-center gap-1">
                                                <span><FaImage /></span>
                                                {!conv?.lastMsg?.text && <span>Image</span>}
                                            </div>
                                        )}
                                        {conv?.lastMsg?.videoUrl && (
                                            <div className="flex items-center gap-1">
                                                <span><FaVideo /></span>
                                                {!conv?.lastMsg?.text && <span>Video</span>}
                                            </div>
                                        )}
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="chat-message text-truncate">
                                                        {conv?.lastMsg?.text}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        ))
                    )}
                </div>
            </div>

            {editUserOpen && <EditUserDetails onClose={() => setEditUserOpen(false)} user={user} />}
            {openSearchUser && <SearchUser onClose={() => setOpenSearchUser(false)} />}
        </div>
    );
};

export default Sidebar;
