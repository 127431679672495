import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: "",
  name: "",
  email: "",
  profile_pic: "",
  token: "",
  role: "",
  onlineUser: [],
  socketConnection: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.profile_pic = action.payload.profile_pic;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.id = "";
      state.name = "";
      state.email = "";
      state.profile_pic = "";
      state.token = "";
      state.role = ""; // Reset role on logout
      state.socketConnection = null;
    },
    setOnlineUser: (state, action) => {
      state.onlineUser = action.payload;
    },
    setSocketConnection: (state, action) => {
      state.socketConnection = action.payload;
    },
    setUserRole: (state, action) => { // New action to set role
      state.role = action.payload;
    }
  },
});

export const { setUser, setToken, logout, setOnlineUser, setSocketConnection, setUserRole } = userSlice.actions;

export default userSlice.reducer;
