import React, { useEffect, useState } from 'react';
import '../ForgotPassword.css'; // Import the CSS file for custom animations

const ForgotPassword = () => {

  return (
    <div className="flex justify-center items-center h-screen px-4">
      <div className={`relative text-center text-lg md:text-xl lg:text-2xl xl:text-3xl`}>
        <p className={`inline-block overflow-hidden whitespace-nowrap border-r-2 border-transparent animate-typing `}>
          We are working on it ;(
        </p>
      </div>
    </div>
  );
}

export default ForgotPassword;
