import React, { useState } from 'react';
import axios from 'axios';
import '../SubmitReports.css'; // Ensure you include the CSS file

const SubmitReport = ({ onClose, onReportSubmitted }) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [reportText, setReportText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const validateInputs = () => {
    if (!name || !phoneNumber || !reportText) {
      setError('All fields are required');
      return false;
    }
    if (!/^\d{10}$/.test(phoneNumber)) {
      setError('Invalid Phone Number');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
        return;
    }

    const reportData = {
        name,
        phone_number: phoneNumber,
        report_text: reportText,
        user_id: 'dummy_user_id',
    };

    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/submit-report`;
    setLoading(true);

    try {
        console.log('Submitting report data:', reportData); // Log data being sent
        const response = await axios.post(URL, reportData, {
            headers: { 'Content-Type': 'application/json' },
        });
        console.log('Response received:', response.data); // Log response data

        onReportSubmitted(response.data.data);
        setName('');
        setPhoneNumber('');
        setReportText('');
        onClose();
    } catch (error) {
        console.error('Error submitting report:', error.response ? error.response.data : error.message);
        setError('Failed to submit the report. Please try again.');
    } finally {
        setLoading(false);
    }
};


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-gray-300 bg-opacity-50 p-6 rounded-lg shadow-lg w-full max-w-lg relative backdrop-blur-sm">
        <button
          type="button"
          className="absolute top-4 right-4 text-black hover:text-gray-700"
          aria-label="Close"
          onClick={onClose}
        >
          &times;
        </button>
        <form onSubmit={handleSubmit}>
          {error && <div className="text-red-600 mb-4">{error}</div>}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="col-span-1">
              <label htmlFor="name" className="block text-sm font-medium text-black">Name</label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-400 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                id="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-span-1">
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-black">Phone Number</label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-400 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                id="phoneNumber"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="reportText" className="block text-sm font-medium text-black">Feedback</label>
            <textarea
              className="mt-1 block w-full rounded-md border-gray-400 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 h-32"
              id="reportText"
              placeholder="Write Here....."
              value={reportText}
              onChange={(e) => setReportText(e.target.value)}
            ></textarea>
          </div>
          <button
            type="submit"
            className={`w-full py-2 px-4 ${loading ? 'bg-gray-400' : 'bg-black'} text-white rounded-md hover:bg-gray-800 focus:outline-none focus:bg-gray-900`}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SubmitReport;
