import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    rooms: [],
    loading: false,
    error: null
};

const roomsSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        setRooms(state, action) {
            state.rooms = action.payload;
        },
        addRoom(state, action) {
            state.rooms.push(action.payload);
        },
        updateRoom(state, action) {
            const index = state.rooms.findIndex(room => room._id === action.payload._id);
            if (index !== -1) {
                state.rooms[index] = action.payload;
            }
        },
        removeRoom(state, action) {
            state.rooms = state.rooms.filter(room => room._id !== action.payload);
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

export const { setRooms, addRoom, updateRoom, removeRoom, setLoading, setError } = roomsSlice.actions;

export default roomsSlice.reducer;
