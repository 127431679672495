import { configureStore } from '@reduxjs/toolkit';
import roomsReducer from './roomSlice';
import userReducer from './userSlice'; 
const store = configureStore({
    reducer: {
        rooms: roomsReducer,
        user: userReducer, 
    }
});

export default store;
