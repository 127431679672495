import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { logout, setSocketConnection, setUser } from '../redux/userSlice';
import Sidebar from '../components/Sidebar';
import io from 'socket.io-client';

const Room = () => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [rooms, setRooms] = useState([]);

  const fetchUserDetails = async () => {
    try {
      const URL = `${process.env.REACT_APP_BACKEND_URL}/api/user-details`;
      const response = await axios({
        url: URL,
        withCredentials: true
      });

      dispatch(setUser(response.data.data));

      if (response.data.data.logout) {
        dispatch(logout());
        navigate('/email');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchRooms = async () => {
    try {
      const URL = `${process.env.REACT_APP_BACKEND_URL}/api/rooms`;
      const response = await axios({
        url: URL,
        withCredentials: true
      });

      setRooms(response.data.rooms);
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchRooms();
  }, [dispatch, navigate]);

  useEffect(() => {
    const socketConnection = io(process.env.REACT_APP_BACKEND_URL, {
      auth: {
        token: localStorage.getItem('token')
      },
    });

    dispatch(setSocketConnection(socketConnection));

    return () => {
      socketConnection.disconnect();
    };
  }, [dispatch]);

  const basePath = location.pathname === '/';
  return (
    <div className='grid lg:grid-cols-[300px,1fr] h-screen max-h-screen'>
      <section className={`bg-white ${!basePath && "hidden"} lg:block`}>
        <Sidebar />
      </section>

      <section className={`${basePath && "hidden"}`}>
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4"></h1>
          <ul>
            {rooms.map(room => (
              <li key={room._id} className="mb-2 p-2 border rounded-md">
                <h2 className="text-lg font-semibold">{room.roomName}</h2>
                <p>Allowed Roles: {room.allowedRoles.join(', ')}</p>
              </li>
            ))}
          </ul>
          <Outlet />
        </div>
      </section>

      <div className={`justify-center items-center flex-col gap-2 hidden ${!basePath ? "hidden" : "lg:flex"}`}>
        <p className='text-lg mt-2 text-slate-500'>Select a room to view details</p>
      </div>
    </div>
  );
}

export default Room;
