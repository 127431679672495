import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Avatar from './Avatar';
import { FaAngleLeft, FaPlus, FaFolder, } from "react-icons/fa";
import { IoClose, IoSend } from 'react-icons/io5'; 
import moment from 'moment';
import uploadFile from '../helpers/uploadFile';
import Loading from './Loading';
import SubmitReport from './SubmitReport';
import { AiOutlineTable } from 'react-icons/ai';
import backgroundImage from '../assets/wallapaper.jpeg';


const MessagePage = () => {
  const params = useParams();
  const socketConnection = useSelector(state => state?.user?.socketConnection);
  const user = useSelector(state => state?.user);
  const [dataUser, setDataUser] = useState({
    name: "",
    email: "",
    profile_pic: "",
    online: false,
    id: ""
  });
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    imageUrl: "",
    videoUrl: ""
  });
  const [loading, setLoading] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  const [isSubmitReportOpen, setIsSubmitReportOpen] = useState(false);
  const [reports, setReports] = useState([]);
  const currentMessage = useRef(null);
  const uploadBoxRef = useRef(null); 

  useEffect(() => {
    if (currentMessage.current) {
      currentMessage.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [allMessage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (uploadBoxRef.current && !uploadBoxRef.current.contains(event.target)) {
        setOpenImageVideoUpload(false);
      }
    };

    if (openImageVideoUpload) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openImageVideoUpload]);

  const handleUploadImageVideoOpen = () => {
    setOpenImageVideoUpload(prev => !prev);
  };

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);
      try {
        const uploadResult = await uploadFile(file);
        const fileType = file.type.split('/')[0];
        setMessage(prev => ({
          ...prev,
          imageUrl: fileType === 'image' ? uploadResult.url : '',
          videoUrl: fileType === 'video' ? uploadResult.url : ''
        }));
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setLoading(false);
        setOpenImageVideoUpload(false);
      }
    }
  };

  const handleClearUploadFile = () => {
    setMessage({
      ...message,
      imageUrl: "",
      videoUrl: ""
    });
  };

  const handleUploadReportsTableClick = () => {
    setIsSubmitReportOpen(true); // Open the SubmitReport component
  };

  useEffect(() => {
    console.log('state change')
    if (socketConnection) {
      socketConnection.emit('message-page', params.userId);
      socketConnection.emit('seen', params.userId);
      socketConnection.on('message-user', (data) => {
        setDataUser(data);
      });
      socketConnection.on('message', (data) => {
        console.log('update', data);
        setAllMessage(data);
      });
    }
  }, [socketConnection, params?.userId, user]);

  const handleOnChange = (e) => {
    const { value } = e.target;
    setMessage(prev => ({
      ...prev,
      text: value
    }));
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.text || message.imageUrl || message.videoUrl) {
      if (socketConnection) {
        socketConnection.emit('new-message', {
          sender: user?.id,
          receiver: params.userId,
          text: message.text,
          imageUrl: message.imageUrl,
          videoUrl: message.videoUrl,
          msgByUserId: user?.id
        });
        setMessage({
          text: "",
          imageUrl: "",
          videoUrl: ""
        });
      }
    }
  };

  const handleReportSubmitted = (reportData) => {
    alert('Your report has been submitted successfully!');
    setReports(prevReports => [...prevReports, reportData]);
    console.log('Submitted report data:', reportData);
    setIsSubmitReportOpen(false);
  };

  return (
    <>
      {isSubmitReportOpen && (
        <SubmitReport 
          onClose={() => setIsSubmitReportOpen(false)}
          onReportSubmitted={handleReportSubmitted}
        />
      )}
      <div style={{ backgroundImage: `url(${backgroundImage})` }} className='bg-no-repeat bg-cover'>
        <header className='sticky top-0 h-16 bg-white flex justify-between items-center px-4'>
          <div className='flex items-center gap-4'>
            <Link to={"/"} className='lg:hidden'>
              <FaAngleLeft size={25} />
            </Link>
            <div>
              <Avatar
                width={50}
                height={50}
                imageUrl={dataUser?.profile_pic}
                name={dataUser?.name}
                userId={dataUser?.id}
              />
            </div>
            <div>
              <h3 className='font-semibold text-lg my-0 text-ellipsis line-clamp-1'>{dataUser?.name}</h3>
              <p className='-my-2 text-sm'>
                {dataUser.online ? <span className='text-primary'>online</span> : <span className='text-slate-400'>offline</span>}
              </p>
            </div>
          </div>
          {/* <DotsMenu /> */}
        </header>

        <section className='h-[calc(100vh-128px)] overflow-x-hidden overflow-y-scroll scrollbar relative bg-slate-200 bg-opacity-50'>
          <div className='flex flex-col gap-2 py-2 mx-2' ref={currentMessage}>
            {allMessage.map((msg, index) => (
              <div key={index} className={`p-2 rounded w-fit max-w-[280px] md:max-w-sm lg:max-w-md ${user.id === msg?.msgByUserId ? "ml-auto bg-teal-100" : "bg-white"} text-wrap text-break custom-text`}>
                <div className='w-full relative'>
                  {msg?.imageUrl && (
                    <img
                      src={msg?.imageUrl}
                      className='w-full h-auto object-scale-down'
                      alt='Message'
                    />
                  )}
                  {msg?.videoUrl && (
                    <video
                      src={msg.videoUrl}
                      className='w-full h-auto object-scale-down'
                      controls
                    />
                  )}
                </div>
                <p className='px-2'>{msg.text}</p>
                <p className='text-xs ml-auto w-fit'>{moment(msg.createdAt).format('hh:mm')}</p>
              </div>
            ))}

            {reports.map((report, index) => (
              <div
                key={index}
                className={`p-2 rounded w-fit max-w-[280px] md:max-w-sm lg:max-w-md ${user.id === report.senderId ? "bg-teal-100 ml-auto" : "bg-yellow-100 ml-auto"} text-wrap text-break custom-text`}
              >
                <div className='w-full'>
                  <p><strong>Name:</strong> {report.name}</p>
                  <p><strong>Phone Number:</strong> {report.phone_number}</p>
                  <p><strong>Report:</strong> {report.report_text}</p>
                </div>
                <p className='text-xs w-fit'>{moment().format('hh:mm')}</p>
              </div>
            ))}

          </div>

          {(message.imageUrl || message.videoUrl) && (
            <div className='w-full h-full sticky bottom-0 bg-slate-700 bg-opacity-30 flex justify-center items-center rounded overflow-hidden'>
              <div className='w-fit p-2 absolute top-0 right-0 cursor-pointer hover:text-red-600' onClick={handleClearUploadFile}>
                <IoClose size={30} />
              </div>
              <div className='bg-white p-3'>
                {message.imageUrl && (
                  <img
                    src={message.imageUrl}
                    alt='Uploaded'
                    className='aspect-square w-full h-full max-w-sm m-2 object-scale-down'
                  />
                )}
                {message.videoUrl && (
                  <video
                    src={message.videoUrl}
                    className='aspect-square w-full h-full max-w-sm m-2 object-scale-down'
                    controls
                    muted
                    autoPlay
                  />
                )}
              </div>
            </div>
          )}

          {loading && (
            <div className='w-full h-full flex sticky bottom-0 justify-center items-center'>
              <Loading />
            </div>
          )}
        </section>

        <section className='h-16 bg-white flex items-center px-4'>
          <div className='relative'>
          <button onClick={handleUploadImageVideoOpen} className='flex justify-center items-center w-11 h-11 rounded-full hover:bg-primary hover:text-white' aria-label="Add Image or Video">
         <FaPlus size={20} />
        </button>

            {openImageVideoUpload && (
              <div className='bg-white shadow rounded absolute bottom-14 w-48 p-2' ref={uploadBoxRef}>
                <form>
                  <label 
                    htmlFor='uploadFile' 
                    className='flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer'
                  >
                    <div className='text-purple-500'>
                      <FaFolder size={20} />
                    </div>
                    <p>Upload File</p>
                  </label>
                  <input
                    type='file'
                    id='uploadFile'
                    className='hidden'
                    accept='image/*,video/*'
                    onChange={handleUploadFile}  
                  />
                  <label 
                    htmlFor='uploadReportsTable' 
                    className='flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer'
                    onClick={handleUploadReportsTableClick}  
                  >
                    <div className='text-purple-500'>
                      <AiOutlineTable size={20} />
                    </div>
                    <p>Upload Reports Table</p>
                  </label>
                </form>
              </div>
            )}
          </div>

          <form className='flex justify-between items-center w-full gap-3 px-3' onSubmit={handleSendMessage}>
            <input
              type='text'
              className='border-none outline-none focus:outline-none w-full'
              placeholder='Type a message'
              value={message.text}
              onChange={handleOnChange}
            />
           <button
            type='submit'
            className='flex justify-center items-center w-11 h-11 rounded-full bg-primary text-white'
            aria-label='Send Message'
          >
            <IoSend size={20} />
          </button>

          </form>
        </section>
      </div>
    </>
  );
};

export default MessagePage;
