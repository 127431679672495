import React from 'react'

const AuthLayouts = ({children}) => {
  return (
    <>

        { children }
    </>
  )
}

export default AuthLayouts
